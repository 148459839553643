import * as React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

import {
  InstantSearchProps,
  Response,
  SearchQueryVariables,
} from '@thg-commerce/enterprise-components/Header'

import { InstantSearch as INSTANT_SEARCH_QUERY } from '../graphql/HeaderQueries.graphql'

export const InstantSearchInjector = (props: {
  children: (props: InstantSearchProps) => React.ReactNode
}) => {
  const [execSearchQuery, queryResult] = useLazyQuery<
    Response,
    SearchQueryVariables
  >(INSTANT_SEARCH_QUERY)
  const { data } = queryResult
  return (
    <React.Fragment>
      {props.children({
        execSearchQuery,
        data,
      })}
    </React.Fragment>
  )
}
